import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        setTimeout(this.close, 3000, this.element)
    }

    close(wrapper) {
        wrapper.querySelector('[aria-hidden]').click();
    }
}