
console.log('Vite ⚡️ Rails')

// import * as Turbo from '@hotwired/turbo'
// Turbo.start()

import 'foundation-sites'

import $ from 'jquery'; //=> ATTENTION: jQuery has to be imported after foundation. Foundation would not need this, it would be satisfied with the settings in vite.config.js
window.$ = $;

// cocoon

import '@nathanvda/cocoon'


// STIMULUS

import { initStimulus } from "vite-stimulus-initializer";
const controllers = import.meta.glob('../javascript/**/*-controller.js', { eager: true })
initStimulus(controllers, 2, { debug: true, exclude: ['components', 'views', 'layout'], folderSeparator: '-'  })

// custom js

import '../javascript/welcome/copy-string-to-clipboard'
import { setup_link_paragraph, onload_pointer } from "~/javascript/welcome/link_paragraph";
import '../javascript/global/studies-chart'

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});
function initPage() {
    $(document).foundation();
    setup_link_paragraph();
    onload_pointer();
    console.log('foundation ready');

}