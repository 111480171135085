import {createPopper} from '@popperjs/core';
import axios from "axios";

window.show_study_popover = function () {
    alert('im showing')
}

window.studies_chart_zoom_plus = function () {
    let wr = document.getElementById('studies-content')
    let w = wr.offsetWidth;
    wr.style.width = `${w + 400}px`;
    let h = wr.offsetHeight;
    wr.style.height = `${h + 20}px`;
}

window.studies_chart_zoom_minus = function () {
    let wr = document.getElementById('studies-content')
    let w = wr.offsetWidth;
    wr.style.width = `${w - 400}px`;
    let h = wr.offsetHeight;
    wr.style.height = `${h - 20}px`;
}

window.studies_chart_popover = function (button_id, popover_id) {

    const popcorn = document.querySelector('#' + button_id);
    const tooltip = document.querySelector('#' + popover_id);
    createPopper(popcorn, tooltip, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });
    tooltip.setAttribute('data-show', '');
    console.log('show-tooltip')
}

window.show_study_popover = function (study_id) {
    axios.get(`/welcome/${study_id}/study_popover`)
        .then(res => {
            let w = document.getElementById('study-popover-wrapper')
            w.innerHTML = res.data
            enter_study_popover(study_id, true);
        });
}

window.list_show_study_popover = function (study_id) {
    axios.get(`/welcome/${study_id}/study_popover`)
        .then(res => {
            let w = document.querySelector(`.study-popover-wrapper-${study_id}`)
            w.innerHTML = res.data
            enter_study_popover(study_id, true);
        });
}

window.close_study_popover = function (study_id) {
    let sid = document.getElementById('study-popover-wrapper')
    let scl = document.querySelector(`.study-popover-wrapper-${study_id}`)
        if(sid){sid.innerHTML = ''}
        if(scl){scl.innerHTML = ''}
    exit_study_popover(study_id, true);
}

window.enter_study_popover = function (id, set_active) {
    let w = document.getElementById('studies-content')
    if (w) {
        let els = w.getElementsByClassName(`study-${id}`)
        for (let i in els) {
            if (set_active) {
                els[i].classList.add('study-active')
            } else {
                els[i].classList.add('study-hovered')
            }
        }
    }
}

window.exit_study_popover = function (id, remove_active) {
    let w = document.getElementById('studies-content')
    if(w) {
        let els = w.getElementsByClassName(`study-${id}`)
        for (let i in els) {
            els[i].classList.remove('study-hovered')
            if (remove_active) {
                els[i].classList.remove('study-active')
            }
        }
    }
}

window.study_expand_abstract = function () {
    document.querySelector('div.abstract-content').classList.remove('hide')
    document.getElementById('expand-abstract-button').classList.add('hide')
    document.getElementById('collapse-abstract-button').classList.remove('hide')

}

window.study_collapse_abstract = function () {
    document.querySelector('div.abstract-content').classList.add('hide')
    document.getElementById('expand-abstract-button').classList.remove('hide')
    document.getElementById('collapse-abstract-button').classList.add('hide')

}