export function setup_link_paragraph() {

    if (!window.setup_link_executed) {
        console.log('START-script' + window.setup_link_executed);
        window.setup_link_executed = true;
        const wc = document.getElementsByClassName('welcome-content-wrapper')[0];
        if (wc) {
            const els = wc.children;
            const no = findGetParameter('paragraph');
            setup_link_paragraph_loop(els, no, 0);
            const li = document.getElementsByTagName('LI');
            setup_link_paragraph_loop(li, no, 1000);

            const link_ins = document.getElementsByClassName('paragraph-link-elements-inside');
            for (let i = 0; i < link_ins.length; i++) {
                const ins = link_ins[i].children
                setup_link_paragraph_loop(ins, no, 2000);
            }
        }
    }
}

export function onload_pointer(){
    const par = findGetParameter('paragraph');
    if (par !== null) {
        location.href = '#paragraph-pointer';
    }
}

function setup_link_paragraph_loop(elements, current_number, add_number) {
    var pointed = false;
    for (let i = 0; i < elements.length; i++) {
        const e = elements[i];
        const id = (e.id ? e.id.replace('#','') : (i + add_number).toString());
        const p = location.href.split('?')[0].split('#')[0] + '?paragraph=' + id;
        const ht = '<div class="link-paragraph-wrapper"><span onClick="copyStringToClipboard(\'' + p + '\')">' + link_svg() + '</span></div>';
        const pointer = '<div class="hand-paragraph-wrapper" id="paragraph-pointer">' + hand_point_right_svg() + '</div>';
        const node = e.nodeName;
        if (node === 'UL') {

        } else {
            e.classList.add('link-paragraph');
            const cont = (id === current_number ? pointer : ht);
            console.log(id + ' / ' + current_number + ' => ' + id === current_number);
            e.innerHTML = '<span id="paragraph-' + id + '" class="link-paragraph-daddy">' + cont + '</span>' + e.innerHTML;
            pointed = true;
        }
    }
}

function hand_point_right_svg() {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M428.8 137.6h-86.177a115.52 115.52 0 0 0 2.176-22.4c0-47.914-35.072-83.2-92-83.2-45.314 0-57.002 48.537-75.707 78.784-7.735 12.413-16.994 23.317-25.851 33.253l-.131.146-.129.148C135.662 161.807 127.764 168 120.8 168h-2.679c-5.747-4.952-13.536-8-22.12-8H32c-17.673 0-32 12.894-32 28.8v230.4C0 435.106 14.327 448 32 448h64c8.584 0 16.373-3.048 22.12-8h2.679c28.688 0 67.137 40 127.2 40h21.299c62.542 0 98.8-38.658 99.94-91.145 12.482-17.813 18.491-40.785 15.985-62.791A93.148 93.148 0 0 0 393.152 304H428.8c45.435 0 83.2-37.584 83.2-83.2 0-45.099-38.101-83.2-83.2-83.2zm0 118.4h-91.026c12.837 14.669 14.415 42.825-4.95 61.05 11.227 19.646 1.687 45.624-12.925 53.625 6.524 39.128-10.076 61.325-50.6 61.325H248c-45.491 0-77.21-35.913-120-39.676V215.571c25.239-2.964 42.966-21.222 59.075-39.596 11.275-12.65 21.725-25.3 30.799-39.875C232.355 112.712 244.006 80 252.8 80c23.375 0 44 8.8 44 35.2 0 35.2-26.4 53.075-26.4 70.4h158.4c18.425 0 35.2 16.5 35.2 35.2 0 18.975-16.225 35.2-35.2 35.2zM88 384c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"/></svg>'
}

function link_svg() {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z"/></svg>';
}


function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

